"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Polyfills for IE. Important
require("./polyfills");
require("@babel/polyfill");
require('./../scss/sites/inspiroidu/inspiroidu.scss');
var index_1 = require("./index");
var inspiroidu_1 = require("./Sites/inspiroidu");
// Initialize scripts that are used in all websites
index_1.Init();
// Import site specific scripts
inspiroidu_1.InitInspiroidu();
// Import styles and scripts included in coreweb packages
index_1.ImportCoreweb();
// Initialize and mount react components
index_1.InitReact();
