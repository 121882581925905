"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function InitInspiroidu() {
    // CategoryFilterBlock
    var submitForm = function (cats, order, allCats, skipCount, showMoreCount, showMorePage, scrollToAnchor, forcedShowMoreCategory) {
        //console.log('skipCount', skipCount);
        // Show loading element
        jQuery('#pagelistwrapper-content-loading').show();
        // Disable pointer events in Category selector
        jQuery('.category-filter-form').css('pointer-events', 'none');
        var rvt = jQuery('input[name=__RequestVerificationToken]').val(); // __RequestVerificationToken
        jQuery.ajax({
            type: 'POST',
            url: '?categories=' + cats + '&ordering=' + order + '&allcategories=' + allCats + '&skipItems=' + skipCount + '&showMoreItems=' + showMoreCount + '&showMorePage=' + showMorePage,
            data: {
                __RequestVerificationToken: rvt
            },
            contentType: 'application/x-www-form-urlencoded; charset=utf-8',
            error: function (xhr) {
                console.log('Error: ' + xhr.statusText);
            },
            success: function (result) {
                var template = jQuery.parseHTML(result);
                var final = $(template).find('#article-results');
                jQuery('#article-results').html(final.html());
                // Hide loading element and enable pointer events in Category selector
                setTimeout(function () {
                    jQuery('#pagelistwrapper-content-loading').hide();
                    jQuery('.category-filter-form').css('pointer-events', 'all');
                    jQuery('#article-results').css('visibility', 'visible');
                    if (scrollToAnchor !== null) {
                        jQuery("html, body").animate({
                            scrollTop: jQuery(scrollToAnchor).position().top
                        });
                        jQuery('.category-filter-block-list .navigation-link').removeClass('refresh');
                    }
                    if (forcedShowMoreCategory !== null) {
                        jQuery('#load-more').attr('data-forced-category', forcedShowMoreCategory);
                    }
                }, 500);
            },
            async: true
        });
    };
    var getSelectedCategories = function () {
        var catString = '';
        var first = true;
        jQuery('input[name=categories]:checked').each(function () {
            if (jQuery(this).prop("value") !== undefined && jQuery(this).prop("value").length > 0) {
                if (!first) {
                    catString += ",";
                }
                catString += jQuery(this).prop("value");
                first = false;
            }
        });
        return catString;
    };
    var getSelectedCategories2 = function (returnAll) {
        var catString = '';
        var first = true;
        var catSelector = (returnAll) ? 'input[name=categories]' : 'input[name=categories]:checked';
        jQuery(catSelector).each(function () {
            if (jQuery(this).prop("value") !== undefined && jQuery(this).prop("value").length > 0) {
                if (!first) {
                    catString += ",";
                }
                catString += jQuery(this).prop("value");
                first = false;
            }
        });
        return catString;
    };
    if (jQuery('form.category-filter-form').length) {
        if (jQuery('.category-filter-block-list .navigation-link').length) {
            jQuery('.category-filter-block-list > li:last-child > .navigation-link').addClass('active');
            jQuery('.category-filter-block-list > li > .navigation-link').addClass('refresh');
        }
        var allCategories = jQuery('input[name=allcategories]').prop('value');
        console.log('allCategories', allCategories);
        /*if (jQuery('.category-filter-block-list .navigation-link').length) {*/ /* Inspiroidu Category filter element has .navigation-link elements. Show latest articles from defined categories. */
        /*console.log('Inspiroidu');*/
        submitForm(allCategories, 'date', allCategories, '0', '0', '1', null, null);
        /*} else {*/ /* Inspiroidu Category filter element has .navigation-link elements. Show articles groupped by category. (category string empty) */
        //    console.log('Inspiroidu viineistä');
        //    submitForm('', 'date', allCategories, '0', '0', '1', null, null);
        //}
    }
    jQuery("input[name=categories]").on("change", function () {
        var selectAll = false;
        // Reset filter when "cat-all" option is selected
        if (jQuery(this).attr("id") === "cat-all") {
            jQuery("input[name=categories]:checked").prop("checked", false);
            jQuery(this).prop('checked', true);
        }
        else {
            jQuery('input[name=categories]#cat-all').prop('checked', false);
        }
        // Fix for category filtering (Inspiroidu viineistä implementation)
        // Reset filter when "new-cat-all" option is selected
        if (jQuery(this).attr("id") === "new-cat-all") {
            selectAll = true;
            jQuery("input[name=categories]:checked").prop("checked", false);
            jQuery(this).prop('checked', true);
        }
        else {
            jQuery("input[name=categories]#new-cat-all").prop("checked", false);
            // No filter categories selected
            if (jQuery("input[name=categories]:checked:not('#new-cat-all')").length === 0) {
                selectAll = true;
                jQuery("input[name=categories]#new-cat-all").prop('checked', true);
            }
        }
        var catString = getSelectedCategories2(selectAll);
        console.log('catString', catString);
        var order = jQuery('input[name=ordering]:checked').prop('value');
        var allCategories = jQuery('input[name=allcategories]').prop('value');
        submitForm(catString, order, allCategories, '0', '0', '1', null, null);
        // When no categories selected, set #cat-all checked
        if (catString.trim().length === 0) {
            //console.log('catString joo', catString);
            jQuery('input[name=categories]#cat-all').prop('checked', true);
        }
    });
    jQuery("input[name=ordering]").on("change", function () {
        var allCategories = jQuery('input[name=allcategories]').prop('value');
        // CategoryLinks start
        var forcedCategory = jQuery('.category-filter-block-list .navigation-link').length;
        var catString = (forcedCategory) ? jQuery('#forced-category').prop('value') : getSelectedCategories();
        // Last filter link "Kaikki" selected
        if (jQuery('.category-filter-block-list > li:last-child > .navigation-link').hasClass('active')) {
            catString = allCategories;
        }
        // CategoryLinks end
        var order = jQuery('input[name=ordering]:checked').prop('value');
        submitForm(catString, order, allCategories, '0', '0', '1', null, null);
    });
    jQuery('#open-mobile-filters').click(function () {
        jQuery('.filters-wrapper').slideDown();
        jQuery(this).hide();
        jQuery('.mobile-category-filter-layer').show();
    });
    jQuery('#hide-mobile-filters').click(function () {
        jQuery('.filters-wrapper').slideUp();
        jQuery('#open-mobile-filters').show();
        jQuery('.mobile-category-filter-layer').hide();
    });
    jQuery('.mobile-category-filter-layer').click(function () {
        jQuery('.filters-wrapper').slideUp();
        jQuery('#open-mobile-filters').show();
        jQuery('.mobile-category-filter-layer').hide();
    });
    window.loadMoreClick = function (amount, page) {
        var allCategories = jQuery('input[name=allcategories]').prop('value');
        // CategoryLinks start
        var forceCategory = jQuery('.category-filter-block-list .navigation-link').length;
        var forcedCategory = (forceCategory) ? jQuery('#forced-category').prop('value') : null;
        var catString = forceCategory ? forcedCategory : getSelectedCategories();
        // Last filter link "Kaikki" selected
        if (jQuery('.category-filter-block-list > li:last-child > .navigation-link').hasClass('active')) {
            catString = allCategories;
        }
        if (jQuery('.category-filter-block-list .navigation-link').length) {
            jQuery('.category-filter-block-list > li > .navigation-link').addClass('refresh');
        }
        // CategoryLinks end
        /* Inspiroidu viineistä */
        if (jQuery('input[name=categories]#new-cat-all').length) {
            var selectAll = (jQuery('input[name=categories]#new-cat-all').prop('checked')) ? true : false;
            catString = getSelectedCategories2(selectAll);
        }
        var order = jQuery('input[name=ordering]:checked').prop('value');
        //const allCategories: string = jQuery('input[name=allcategories]').prop('value');
        var showMoreAmount = amount;
        var showMorePage = page;
        submitForm(catString, order, allCategories, '0', showMoreAmount, showMorePage, null, forcedCategory);
    };
    jQuery(window).on("scroll", function () {
        if (jQuery('.category-filter-block-list .navigation-link').length) {
            var marginTop = parseInt(jQuery('form.category-filter-form').css('margin-top'));
            if (jQuery(window).scrollTop() <= marginTop) {
                jQuery('form.category-filter-form .filters-wrapper').css('position', 'absolute').css('top', '0px');
            }
            else {
                jQuery('form.category-filter-form .filters-wrapper').css('position', 'fixed').css('top', '0');
            }
        }
        else {
            var marginTop = parseInt(jQuery('form.category-filter-form').css('margin-top'));
            if (jQuery(window).scrollTop() <= marginTop) {
                jQuery('form.category-filter-form .filters-wrapper').css('position', 'relative').css('top', '0px');
            }
            else {
                jQuery('form.category-filter-form .filters-wrapper').css('position', 'fixed').css('top', '0');
            }
        }
    });
    // CategoryLinks start
    jQuery('.category-filter-block-list .navigation-link').click(function (e) {
        e.preventDefault();
        jQuery('#forced-category').val('');
        var refreshPage = (jQuery(this).hasClass('refresh'));
        jQuery('.category-filter-block-list .navigation-link').removeClass('active');
        jQuery(this).addClass('active');
        var linkHref = jQuery(e.target).attr('href');
        var anchorId = (linkHref !== undefined && linkHref.indexOf('#') > -1) ? linkHref.substring(linkHref.indexOf('#')) : null;
        /*        console.log('anchorId', anchorId);*/
        if (anchorId !== null) {
            if (anchorId === "#all") {
                jQuery('.category-filter-block-list > li > .navigation-link').removeClass('active');
                jQuery(this).addClass('active');
                jQuery('.category-filter-block-list > li > .navigation-link').addClass('refresh');
                var allCategories = jQuery('input[name=allcategories]').prop('value');
                var order = jQuery('input[name=ordering]:checked').prop('value');
                submitForm(allCategories, order, allCategories, '0', '0', '1', null, null);
                //submitForm(allCategories, order, allCategories, '0', '0', '1', anchorId, null);
            }
            else {
                if (refreshPage) {
                    var allCategories = jQuery('input[name=allcategories]').prop('value');
                    var order = jQuery('input[name=ordering]:checked').prop('value');
                    submitForm('', order, allCategories, '0', '0', '1', anchorId, null);
                }
                else {
                    var topPos = jQuery(anchorId).position().top;
                    if (jQuery(window).innerWidth() <= 768) {
                        if (jQuery(anchorId).position().top > 70) {
                            topPos = jQuery(anchorId).position().top - 140;
                        }
                        else {
                            topPos = 0;
                        }
                    }
                    jQuery("html, body").animate({
                        scrollTop: topPos
                    });
                }
            }
        }
        else {
            location.href = linkHref;
        }
    });
    // CategoryLinks end
    // Page List Wrapper Block
    window.filterList = function () {
        event.preventDefault();
        jQuery(window).scrollTop(0);
        var skipCount = jQuery(event.target).data('skip');
        var catString = jQuery(event.target).data('category');
        var order = jQuery('input[name=ordering]:checked').prop('value');
        var allCategories = jQuery('input[name=allcategories]').prop('value');
        jQuery("input[name=categories]").prop("checked", false);
        jQuery("input[name=categories][value=" + catString + "]").prop("checked", true);
        // CategoryLinks start
        if (jQuery('.category-filter-block-list .navigation-link').length) {
            // Last filter link "Kaikki" selected
            if (jQuery('.category-filter-block-list > li:last-child > .navigation-link').hasClass('active')) {
                catString = allCategories;
            }
            jQuery('.category-filter-block-list > li > .navigation-link').removeClass('active');
            jQuery('.category-filter-block-list > li > .navigation-link').addClass('refresh');
            // Set active link by category index
            var catIdx = jQuery(event.target).data('filter-link-idx');
            if (catIdx !== undefined) {
                catIdx++;
                jQuery('.category-filter-block-list > li:nth-child(' + catIdx + ') > .navigation-link').addClass('active');
            }
            else {
                // Set active link by category text
                var linkText_1 = jQuery(event.target).data('filter-link-title');
                var activeLinkByText = $('.category-filter-block-list > li > .navigation-link').filter(function (index) { return $(this).text() === linkText_1; });
                jQuery(activeLinkByText).addClass('active');
            }
            setTimeout(function () {
                jQuery('#forced-category').val(catString);
                jQuery('#load-more').attr('data-forced-category', catString);
            }, 500);
        }
        // CategoryLinks end
        submitForm(catString, order, allCategories, skipCount, '0', '1', null, null);
    };
}
exports.InitInspiroidu = InitInspiroidu;
